import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Clustering Algorithms`}</h1>
    <p>{`A clustering algorithm is any technique for taking a collection of data and partitioning it into "`}<a parentName="p" {...{
        "href": "/clusters",
        "title": "clusters"
      }}>{`clusters`}</a>{`" based on characteristics of the data in question.
`}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` uses clustering algorithms to identity `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` in a `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{`, and these play a key role in our `}<a parentName="p" {...{
        "href": "/Analysis",
        "title": "Analysis"
      }}>{`Analysis`}</a>{` techniques and `}<a parentName="p" {...{
        "href": "/algorithms",
        "title": "algorithms"
      }}>{`algorithms`}</a>{`.`}</p>
    <p>{`The primary clustering algorithm used by Polis to identity opinion groups is called `}<a parentName="p" {...{
        "href": "/K-means",
        "title": "K means"
      }}>{`K means`}</a>{`.`}</p>
    <p>{`For more see:`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/opinion-groups",
          "title": "opinion groups"
        }}>{`opinion groups`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/algorithms",
          "title": "algorithms"
        }}>{`algorithms`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      